import React from 'react';
import NavigationLinks from '../components/molecules/NavigationLinks'
import IndexPage from '../components/organisms/index-page/index-page';

const Home: React.FC = () => {
  return (
    <div>
      {/* <NavigationLinks /> */}
      <IndexPage />
    </div>
  )
}


export default Home;